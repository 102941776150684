import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  fontSize,
} from '../styles';
import { Container, Heading } from './ui';

const StyledContentBlocks = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 10px;

  ${minBreakpointQuery.small`
    margin-bottom: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 30px;
  `}
`;

const StyledItems = styled.div`
  ${minBreakpointQuery.medium`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 60px;
  `}

  ${minBreakpointQuery.large`
    column-gap: 100px;
  `}

  ${minBreakpointQuery.xlarge`
    column-gap: 140px;
  `}

  ${minBreakpointQuery.xxlarge`
    column-gap: 180px;
  `}

  ${minBreakpointQuery.xxxlarge`
    column-gap: 220px;
  `}
`;

const StyledItem = styled.article`
  padding: 20px 0;
  border-bottom: 1px solid ${brandColours.primary};

  ${minBreakpointQuery.small`
    padding-top: 30px;
    padding-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 40px;
    padding-bottom: 40px;
  `}
`;

const StyledSubheading = styled.h3`
  color: ${brandColours.primary};
  ${fontSize(22)};

  ${minBreakpointQuery.small`
    ${fontSize(26)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(30)};
  `}
`;

const StyledText = styled.p`
  margin-top: 16px;
  line-height: 1.7;
`;

const ContentBlocks = ({ heading, items }) => (
  <StyledContentBlocks>
    <Container>
      <StyledHeading>{heading}</StyledHeading>
      <StyledItems>
        {items.map(({ id, heading, text }) => (
          <StyledItem key={id}>
            <StyledSubheading>{heading}</StyledSubheading>
            <StyledText>{text}</StyledText>
          </StyledItem>
        ))}
      </StyledItems>
    </Container>
  </StyledContentBlocks>
);

export default ContentBlocks;
