import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { Container, Heading, Video } from './ui';

const StyledInternalVideo = styled.section`
  ${({ isNewsSingle }) =>
    isNewsSingle ? sectionMargins('30px', '50px') : sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  margin-bottom: 30px;

  ${minBreakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 50px;
  `}
`;

const InternalVideo = ({ heading, video, isNewsSingle }) => (
  <StyledInternalVideo isNewsSingle={isNewsSingle}>
    <Container narrow={true}>
      <StyledHeading>{heading}</StyledHeading>
      <Video data={video} />
    </Container>
  </StyledInternalVideo>
);

export default InternalVideo;
