import React from 'react';
import styled from 'styled-components';
import { ArrowButton } from './ui';
import {
  brandColours,
  breakpointSizes,
  fontSize,
  fontWeights,
  fluidFontSize,
  minBreakpointQuery,
  standardColours,
} from '../styles';

const StyledContentCta = styled.article`
  width: 100%;

  ${minBreakpointQuery.medium`
    padding-right: 20px;
    max-width: 480px;
  `}

  ${minBreakpointQuery.large`
     padding-right: 0;
  `}
`;

const StyledOverline = styled.p`
  padding: 0 0 20px;
  color: ${brandColours.primary};
  ${fontSize(14)}
  font-weight: ${fontWeights.semibold};
  letter-spacing: 2.5px;
  line-height: 2rem;
  text-transform: uppercase;

  ${minBreakpointQuery.medium`
    padding: 10px 0 20px;
    color: ${standardColours.black};
    ${fontSize(16)};
  `}
`;

const StyledHeading = styled.h2`
  margin-bottom: 15px;
  color: ${brandColours.primary};
  ${fluidFontSize(
    '30px',
    '45px',
    breakpointSizes.tiny,
    breakpointSizes.medium
  )};
  line-height: 4rem;

  ${minBreakpointQuery.small`
    line-height: 5rem;
  `}

  ${minBreakpointQuery.medium`
    line-height: 6rem;
  `}
`;

const StyledText = styled.p`
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 3.2rem;

  ${minBreakpointQuery.medium`
    line-height: 3.6rem;
  `}
`;

export const ContentCta = ({ overline, heading, text, ctaLink }) => (
  <StyledContentCta>
    {overline && <StyledOverline>{overline}</StyledOverline>}
    <StyledHeading>{heading}</StyledHeading>
    {text && <StyledText>{text}</StyledText>}
    <ArrowButton to={ctaLink.page}>{ctaLink.text}</ArrowButton>
  </StyledContentCta>
);
