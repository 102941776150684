import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  brandColours,
  fontWeights,
  fontSize,
} from '../styles';
import { Container, Svg, Heading, Link } from './ui';
import { buildUrl } from '../utils';
import stripBlueHorizontalLarge from '../images/strip-blue-horizontal-large.inline.svg';
import arrowIcon from '../images/right-arrow.inline.svg';

const StyledTextCta = styled.section`
  ${sectionMargins()};
`;

const StyledInner = styled.div`
  min-height: 340px;
  background-color: ${brandColours.secondary};
  cursor: pointer;

  ${maxBreakpointQuery.smedium`
    position: relative;
    display: flex;
  `}

  ${minBreakpointQuery.smedium`
    display: grid;
    grid-template-columns: 1fr 1.4fr;
    align-items: flex-end;
    min-height: 380px;
  `}

  ${minBreakpointQuery.large`
    min-height: 420px;
  `}

  ${minBreakpointQuery.xxlarge`
    min-height: 460px;
  `}
`;

const StyledStrip = styled(Svg)`
  ${maxBreakpointQuery.smedium`
    position: absolute;
    top: 0;
    left: 0;
    height: 80px;
  `}

  ${maxBreakpointQuery.tsmall`
    height: 60px;
  `}
`;

const StyledContent = styled.div`
  position: relative;
  margin: 30px;
  padding-right: 30px;
  background-color: ${brandColours.secondary};
  cursor: pointer;

  ${maxBreakpointQuery.smedium`
    margin-top: auto;
    padding-top: 100px;
    width: 100%;
  `}

  ${maxBreakpointQuery.tsmall`
    padding-top: 80px;
  `}

  ${minBreakpointQuery.smedium`
    margin: 40px;
    padding-top: 100px;
    padding-right: 40px;
  `}

  ${minBreakpointQuery.large`
    margin: 50px;
    padding-right: 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin: 60px;
  `}
`;

const StyledText = styled.p`
  margin-top: 4px;
  color: ${brandColours.primary};
  font-weight: ${fontWeights.semibold};
  ${fontSize(16)};

  ${minBreakpointQuery.small`
    ${fontSize(19)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 6px;
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.xxlarge`
    ${fontSize(25)};
  `}
`;

const StyledArrow = styled(Svg)`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 28px;
  width: 28px;
  fill: ${brandColours.primary};

  ${minBreakpointQuery.small`
    height: 32px;
    width: 32px;
  `}

  ${minBreakpointQuery.large`
    height: 36px;
    width: 36px;
  `}
`;

const TextCta = ({ heading, text, link }) => (
  <StyledTextCta>
    <Container>
      <StyledInner
        onClick={() => navigate(buildUrl(link.page.slug, link.page))}
      >
        <StyledStrip image={stripBlueHorizontalLarge} />
        <StyledContent>
          <Heading>
            <Link to={link.page}>{heading}</Link>
          </Heading>
          {text && <StyledText>{text}</StyledText>}
          <StyledArrow image={arrowIcon} />
        </StyledContent>
      </StyledInner>
    </Container>
  </StyledTextCta>
);

export default TextCta;
