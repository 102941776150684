import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { Container, Heading, Video } from './ui';

const StyledExternalVideo = styled.section`
  ${({ isNewsSingle }) =>
    isNewsSingle ? sectionMargins('30px', '50px') : sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  margin-bottom: ${({ isNewsSingle }) => (isNewsSingle ? '20' : '30')}px;

  ${minBreakpointQuery.small`
    margin-bottom: ${({ isNewsSingle }) => (isNewsSingle ? '25' : '40')}px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: ${({ isNewsSingle }) => (isNewsSingle ? '30' : '50')}px;
  `}
`;

const ExternalVideo = ({ heading, video, isNewsSingle }) => (
  <StyledExternalVideo isNewsSingle={isNewsSingle}>
    <Container narrow={true}>
      <StyledHeading isNewsSingle={isNewsSingle}>{heading}</StyledHeading>
      <Video data={video} />
    </Container>
  </StyledExternalVideo>
);

export default ExternalVideo;
