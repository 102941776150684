import React from 'react';
import { navigate, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  sectionMargins,
} from '../styles';
import { Container, Link, Svg } from './ui';
import { buildUrl } from '../utils';
import arrowIcon from '../images/right-arrow.inline.svg';

const StyledImageContentCta = styled.section`
  ${sectionMargins(undefined, '100px')};
`;

const StyledContentHover = styled.div`
  display: none;

  ${minBreakpointQuery.smedium`
    position: absolute;
    top: 0;
    ${({ alt }) => (alt === 0 ? 'right: -200px;' : 'left: -200px;')}
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 45px 20px;
    height: 100%;
    width: 200px;
    text-align: center;
    background-color: ${brandColours.primary};
    transition: all 0.25s ease-in-out;
  `}
`;

const StyledCTAIconInner = styled(Svg)`
  position: absolute;
  top: 23px;
  right: 25px;
  display: flex;
  margin: 0 auto 25px;
  height: 18px;
  width: 18px;
  min-height: 18px;
  min-width: 18px;
  fill: ${brandColours.primary};
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  ${minBreakpointQuery.smedium`
    display: none;
  `}
`;

const StyledInner = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover ${StyledCTAIconInner},
  &:focus ${StyledCTAIconInner},
  &:active ${StyledCTAIconInner} {
    opacity: 1;
  }

  ${minBreakpointQuery.smedium`
    &:hover
    ${StyledContentHover},
    &:focus
    ${StyledContentHover},
    &:active
    ${StyledContentHover} {
      display: flex;
      ${({ alt }) => (alt === 0 ? 'right: 0;' : 'left: 0;')}
    `}
  }
`;

const StyledContent = styled.div`
  padding-top: 20px;
  background-color: ${standardColours.white};

  ${minBreakpointQuery.small`
    position: absolute;
    bottom: 0;
    ${({ alt }) => (alt ? 'right: 0;' : 'left: 0;')}
    padding-right: 45px;
    padding-bottom: 20px;
    padding-left: 30px;
    max-width: 480px;
  `}

  ${minBreakpointQuery.medium`
    padding: 30px 40px;
  `}

  ${minBreakpointQuery.large`
    padding: 40px 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding: 50px 60px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    padding: 60px 70px;
  `}
`;

const StyledHeading = styled.h2`
  margin-bottom: 8px;
  color: ${brandColours.primary};
  ${fontSize(20)};

  ${minBreakpointQuery.medium`
    ${fontSize(25)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(30)};
  `}
`;

const StyledText = styled.p`
  margin-top: 8px;
  color: ${brandColours.tertiary};
  ${fontSize(14)};
  line-height: 1.7;

  ${minBreakpointQuery.medium`
    margin-top: 10px;
    ${fontSize(16)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 12px;
  `}
`;

const StyledCTAIcon = styled(Svg)`
  display: none;
  margin: 0 auto 25px;
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  fill: ${brandColours.primary};

  ${minBreakpointQuery.smedium`
    display: flex;
    height: 44px;
    width: 44px;
    min-height: 44px;
    min-width: 44px;
    fill: ${standardColours.white};
  `}
`;

const StyledCTAText = styled.span`
  display: none;

  ${minBreakpointQuery.smedium`
    display: flex;
    margin: 0 auto;
    ${fontSize(16)};
    color: ${standardColours.white};
    text-transform: uppercase;
  `}
`;

const ImageContentCta = ({ heading, text, image, link, alt }) => (
  <StyledImageContentCta>
    <Container>
      <StyledInner
        onClick={() => navigate(buildUrl(link.page.slug, link.page))}
        alt={alt}
      >
        <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
        <StyledContent alt={alt}>
          <StyledHeading>
            <Link to={link.page}>{heading}</Link>
          </StyledHeading>
          {text && <StyledText>{text}</StyledText>}
          <StyledCTAIconInner image={arrowIcon} />
        </StyledContent>
        <StyledContentHover alt={alt}>
          <StyledCTAIcon image={arrowIcon} />
          <StyledCTAText>View Service</StyledCTAText>
        </StyledContentHover>
      </StyledInner>
    </Container>
  </StyledImageContentCta>
);

export default ImageContentCta;

export const ImageContentCtaImageFragment = graphql`
  fragment ImageContentCtaImageFragment on DatoCmsFileField {
    gatsbyImageData(width: 1140, height: 540)
    alt
  }
`;
