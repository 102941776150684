import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  visuallyHidden,
  standardTransition,
} from '../styles';
import { Link, Svg } from './ui';
import { buildUrl } from '../utils';
import stripBlueHorizontalLarge from '../images/strip-blue-horizontal-large.inline.svg';
import stripBlueVertical from '../images/strip-blue-vertical.inline.svg';
import arrowIcon from '../images/right-arrow.inline.svg';

const StyledImageCtaCards = styled.section`
  ${sectionMargins()};
`;

const StyledHiddenHeading = styled.h2`
  ${visuallyHidden()};
`;

const StyledInner = styled.div`
  ${minBreakpointQuery.large`
    display: grid;
    grid-template-columns: 3fr 1fr;
  `}
`;

const StyledItems = styled.div`
  ${minBreakpointQuery.large`
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  `}
`;

const StyledFeaturedImage = styled(GatsbyImage)`
  ${maxBreakpointQuery.large`
    ${visuallyHidden()};
  `}

  ${minBreakpointQuery.large`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `}
`;

// moved up for hover
const StyledView = styled.span`
  ${maxBreakpointQuery.large`
    ${visuallyHidden()};
  `}

  ${minBreakpointQuery.large`
    display: block;
    margin: 20px 0;
    padding: 8px 4px;
    color: ${standardColours.white};
    font-weight: ${fontWeights.semibold};
    ${fontSize(14)};
    border-bottom: 3px solid ${standardColours.white};
    opacity: 0;
    transition: ${standardTransition('opacity')};
  `}

  ${minBreakpointQuery.xxlarge`
    ${fontSize(16)};
  `}
`;

// moved up for hover
const StyledContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${minBreakpointQuery.small`
    padding: 45px;
  `}

  ${minBreakpointQuery.large`
    justify-content: flex-end;
    transform: translateY(60px);
    transition: ${standardTransition('transform')};
  `}

  ${minBreakpointQuery.xxlarge`
    padding: 60px;
  `}

  &:after {
    ${minBreakpointQuery.large`
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      height: 45px;
      width: 2px;
      background-color: ${standardColours.white};
      opacity: 0;
      transform: translateX(-50%);
      transition: ${standardTransition('opacity')};
    `}

    ${minBreakpointQuery.xxlarge`
      height: 60px;
    `}
  }
`;

const StyledItem = styled.article`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  ${minBreakpointQuery.large`
    height: 400px;
    border-left: 1px solid ${standardColours.white};
  `}

  ${minBreakpointQuery.xlarge`
    height: 500px;
  `}

  ${minBreakpointQuery.xxlarge`
    height: 600px;
  `}

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${standardColours.transparentBlack(0.4)};
  }

  &:first-child {
    ${minBreakpointQuery.large`
      border-left: none;
    `}
  }

  &:hover {
    ${StyledContent} {
      ${minBreakpointQuery.large`
        transform: translateY(0);
      `}

      &:after {
        ${minBreakpointQuery.large`
          opacity: 1;
        `}
      }
    }

    ${StyledView} {
      ${minBreakpointQuery.large`
        opacity: 1;
      `}
    }
  }
`;

const StyledImage = styled(GatsbyImage)`
  min-height: 240px;

  ${minBreakpointQuery.large`
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: ${standardTransition('opacity')};
  `}

  ${({ isFeaturedImageShowing }) => {
    if (isFeaturedImageShowing) {
      return css`
        ${minBreakpointQuery.large`
          opacity: 0;
        `}
      `;
    }
  }}
`;

const StyledHeading = styled.h3`
  color: ${standardColours.white};
  font-weight: ${fontWeights.bold};
  ${fontSize(25)};
`;

const StyledLink = styled(Link)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 60px 30px;
  color: ${brandColours.primary};
  font-weight: ${fontWeights.semibold};
  ${fontSize(25)};
  background-color: ${brandColours.secondary};
  overflow: hidden;

  ${maxBreakpointQuery.xxlarge`
    padding-top: 130px;
  `}

  ${minBreakpointQuery.small`
    padding-right: 45px;
    padding-left: 45px;
    ${fontSize(30)};
  `}

  ${minBreakpointQuery.xxlarge`
    padding-right: 60px;
    padding-left: 60px;
    ${fontSize(35)};
  `};
`;

const StyledStrip = styled(Svg)`
  position: absolute;
  top: 0;

  ${({ direction }) => {
    if (direction === 'horizontal') {
      return css`
        ${maxBreakpointQuery.xxlarge`
          left: 0;
          height: 70px;
        `}

        ${minBreakpointQuery.xxlarge`
          display: none;
        `}
      `;
    } else {
      return css`
        ${maxBreakpointQuery.xxlarge`
          display: none;
        `}

        ${minBreakpointQuery.xxlarge`
          right: 0;
          width: 100px;
        `}
      `;
    }
  }}
`;

const StyledArrow = styled(Svg)`
  margin-left: 15px;
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  fill: ${brandColours.primary};

  ${minBreakpointQuery.small`
    margin-left: 20px;
    height: 34px;
    width: 34px;
    min-height: 34px;
    min-width: 34px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-left: 25px;
    height: 38px;
    width: 38px;
    min-height: 38px;
    min-width: 38px;
  `}
`;

const ImageCtaCards = ({ heading, items, link }) => {
  const [featuredImage, setFeaturedImage] = useState();

  return (
    <StyledImageCtaCards>
      <StyledHiddenHeading>{heading}</StyledHiddenHeading>
      <StyledInner>
        <StyledItems>
          {featuredImage && (
            <StyledFeaturedImage
              image={featuredImage.gatsbyImageData}
              alt={featuredImage.alt}
            />
          )}
          {items.map(({ id, image, heading, link }) => (
            <StyledItem
              key={id}
              onClick={() => navigate(buildUrl(link.page.slug, link.page))}
              onMouseEnter={() => setFeaturedImage(image)}
              onMouseLeave={() => setFeaturedImage(null)}
            >
              <StyledImage
                image={image.gatsbyImageData}
                alt={image.alt}
                isFeaturedImageShowing={featuredImage}
              />
              <StyledContent>
                <StyledHeading>
                  <Link to={link.page}>{heading}</Link>
                </StyledHeading>
                <StyledView>{link.text}</StyledView>
              </StyledContent>
            </StyledItem>
          ))}
        </StyledItems>
        <StyledLink to={link.page}>
          <StyledStrip
            image={stripBlueHorizontalLarge}
            direction="horizontal"
          />
          <StyledStrip image={stripBlueVertical} direction="vertical" />
          {link.text}
          <StyledArrow image={arrowIcon} />
        </StyledLink>
      </StyledInner>
    </StyledImageCtaCards>
  );
};

export default ImageCtaCards;
