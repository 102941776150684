import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  sectionMargins,
  fontWeights,
  fluidFontSize,
  brandColours,
  minBreakpointQuery,
} from '../styles';
import stripBlueHorizontalSmall from '../images/strip-blue-horizontal-small.inline.svg';
import { Container, Svg } from './ui';

const StyledTestimonial = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled.h2`
  margin-bottom: 20px;
  color: ${brandColours.primary};
  ${fluidFontSize(
    '36px',
    '46px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
`;

const StyledQuote = styled.blockquote`
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 40px 20px 50px;
  color: ${brandColours.primary};
  ${fluidFontSize(
    '25px',
    '35px',
    breakpointSizes.tiny,
    breakpointSizes.xlarge
  )};
  font-weight: ${fontWeights.bold};
  background-color: ${brandColours.secondary};

  ${minBreakpointQuery.tiny`
    padding: 40px;
  `}

  ${minBreakpointQuery.medium`
    padding: 60px;
  `}

  ${minBreakpointQuery.xlarge`
    padding: 60px 80px 80px;
  `}
`;

const StyledText = styled.p`
  line-height: 4rem;

  ${minBreakpointQuery.small`
    line-height: 5rem;
  `}

  ${minBreakpointQuery.xlarge`
    line-height: 6rem;
  `}
`;

const StyledCite = styled.cite`
  display: block;
  margin: 20px 0;
  ${fluidFontSize(
    '20px',
    '30px',
    breakpointSizes.tiny,
    breakpointSizes.xlarge
  )};
  font-weight: ${fontWeights.regular};
  line-height: 1.1;

  ${minBreakpointQuery.xlarge`
    margin-bottom: 0;
  `}
`;

const StyledStrip = styled(Svg)`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 30px;

  ${minBreakpointQuery.small`
    height: 35px;
  `}

  ${minBreakpointQuery.large`
    height: 40px;
  `}
`;

const Testimonial = ({ heading, quote, author }) => (
  <StyledTestimonial>
    <Container>
      <StyledHeading>{heading}</StyledHeading>
      <StyledQuote>
        <StyledText>
          "{quote}"<StyledCite>- {author}</StyledCite>
        </StyledText>
        <StyledStrip image={stripBlueHorizontalSmall} />
      </StyledQuote>
    </Container>
  </StyledTestimonial>
);

export default Testimonial;
