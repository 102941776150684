import React from 'react';
import styled from 'styled-components';
import { Link } from './ui';
import {
  breakpointSizes,
  minBreakpointQuery,
  brandColours,
  fluidFontSize,
  fontWeights,
  standardTransition,
} from '../styles';

const StyledFeaturedLinks = styled.ul`
  margin-top: 25px;
  width: 100%;

  ${minBreakpointQuery.medium`
    margin-top: 30px;
    max-width: 485px;
  `}
`;

const StyledItem = styled.li`
  border-bottom: 1px solid ${brandColours.secondary};
`;

const StyledLink = styled(Link)`
  padding: 0 10px;
  width: 100%;
  color: ${brandColours.secondary};
  ${fluidFontSize(
    '30px',
    '40px',
    breakpointSizes.tiny,
    breakpointSizes.medium
  )};
  font-weight: ${fontWeights.semibold};
  line-height: 8rem;
  transition: ${standardTransition('color')};

  &:hover {
    color: ${brandColours.primary};
  }

  ${minBreakpointQuery.medium`
    padding: 10px;
  `}
`;

export const FeaturedLinks = ({ featuredLinks }) => (
  <StyledFeaturedLinks>
    {featuredLinks.map(link => (
      <StyledItem key={link.id}>
        <StyledLink to={link.page}>{link.text}</StyledLink>
      </StyledItem>
    ))}
  </StyledFeaturedLinks>
);
