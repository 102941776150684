import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  fontSize,
} from '../styles';
import { Container, Heading } from './ui';
import { GatsbyImage } from 'gatsby-plugin-image';

const StyledTeamProfiles = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 30px;
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 50px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 60px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-bottom: 70px;
  `}
`;

const StyledItems = styled.div`
  display: grid;
  gap: 30px 20px;

  ${minBreakpointQuery.tiny`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.small`
    row-gap: 40px;
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${minBreakpointQuery.large`
    row-gap: 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    row-gap: 60px;
  `}
`;

const StyledItem = styled.article``;

const StyledName = styled.h3`
  margin-top: 18px;
  color: ${brandColours.primary};
  ${fontSize(22)};

  ${minBreakpointQuery.small`
    ${fontSize(26)};
  `}

  ${minBreakpointQuery.large`
     margin-top: 22px;
    ${fontSize(30)};
  `}
`;

const StyledRole = styled.p`
  margin-top: 6px;

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 8px;
    ${fontSize(20)};
  `}
`;

const TeamProfiles = ({ heading, items }) => (
  <StyledTeamProfiles>
    <Container>
      <StyledHeading>{heading}</StyledHeading>
      <StyledItems>
        {items.map(({ gatsbyImageData, alt, title }, id) => (
          <StyledItem key={id}>
            <GatsbyImage image={gatsbyImageData} alt={alt} />
            <StyledName>{title}</StyledName>
            <StyledRole>{alt}</StyledRole>
          </StyledItem>
        ))}
      </StyledItems>
    </Container>
  </StyledTeamProfiles>
);

export default TeamProfiles;
