import React from 'react';
import styled from 'styled-components';
import { Container, Heading } from './ui';
import { FeaturedLinks } from './FeaturedLinks';
import {
  minBreakpointQuery,
  breakpointSizes,
  fluidFontSize,
  fontWeights,
  sectionMargins,
} from '../styles';

const StyledContentFeaturedLinks = styled.section`
  ${sectionMargins()};
`;

const StyledContainer = styled(Container)`
  ${minBreakpointQuery.large`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `}
`;

const StyledInner = styled.section`
  max-width: 780px;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 15px;
`;

const StyledSubHeading = styled.h3`
  margin-bottom: 15px;
  ${fluidFontSize('20px', '35px', breakpointSizes.tiny, breakpointSizes.large)};
  font-weight: ${fontWeights.regular};

  ${minBreakpointQuery.large`
    line-height: 5rem;
  `}
`;

const StyledSummary = styled.p`
  ${fluidFontSize('16px', '18px', breakpointSizes.tiny, breakpointSizes.large)};
  line-height: 3rem;
`;

const ContentFeaturedLinks = ({ heading, subHeading, text, featuredLinks }) => (
  <StyledContentFeaturedLinks>
    <StyledContainer>
      <StyledHeading>{heading}</StyledHeading>
      <StyledInner>
        {subHeading && <StyledSubHeading>{subHeading}</StyledSubHeading>}
        {text && <StyledSummary>{text}</StyledSummary>}
        <FeaturedLinks featuredLinks={featuredLinks} />
      </StyledInner>
    </StyledContainer>
  </StyledContentFeaturedLinks>
);

export default ContentFeaturedLinks;
