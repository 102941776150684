import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { sectionMargins } from '../styles';
import Accordion from './Accordion';
import ContainedImage from './ContainedImage';
import ContentBlocks from './ContentBlocks';
import Content from './Content';
import ContentFeaturedLinks from './ContentFeaturedLinks';
import ContentCtaFeaturedLinks from './ContentCtaFeaturedLinks';
import ExternalVideo from './ExternalVideo';
import FeaturedLogos from './FeaturedLogos';
import FeaturedProjects from './FeaturedProjects';
import FullWidthImage from './FullWidthImage';
import HighlightedLogos from './HighlightedLogos';
import ImageContentCta from './ImageContentCta';
import ImageContent from './ImageContent';
import ImageCtaCards from './ImageCtaCards';
import ImageSlider from './ImageSlider';
import InternalVideo from './InternalVideo';
import LatestNews from './LatestNews';
import LogosStrip from './LogosStrip';
import StatementText from './StatementText';
import Tabs from './Tabs';
import TeamProfiles from './TeamProfiles';
import Testimonial from './Testimonial';
import TextCta from './TextCta';

const StyledModularBlocks = styled.div`
  ${sectionMargins(undefined, '80px')};
`;

const ModularBlocks = ({ items, isNewsSingle }) => {
  const ModularBlocksComponent = isNewsSingle
    ? StyledModularBlocks
    : React.Fragment;

  return (
    <ModularBlocksComponent>
      {items.map(item => (
        <React.Fragment key={item.id}>
          {item.model.apiKey === 'accordion_modular_block' && (
            <Accordion
              heading={item.instance.heading}
              items={item.instance.accordionItems}
            />
          )}
          {item.model.apiKey === 'contained_image_modular_block' && (
            <ContainedImage image={item.image} isNewsSingle={isNewsSingle} />
          )}
          {item.model.apiKey === 'content_featured_links_modular_block' && (
            <ContentFeaturedLinks
              heading={item.heading}
              subHeading={item.subheading}
              text={item.text}
              featuredLinks={item.featuredLinks}
            />
          )}
          {item.model.apiKey === 'content_blocks_modular_block' && (
            <ContentBlocks
              heading={item.instance.heading}
              items={item.instance.contentBlocks}
            />
          )}
          {item.model.apiKey === 'content_modular_block' && (
            <Content
              html={item.content}
              contain={item.contain}
              twoColumns={item.twoColumns}
              highlight={item.highlight}
              isNewsSingle={isNewsSingle}
            />
          )}
          {item.model.apiKey === 'content_cta_featured_links_modular_block' && (
            <ContentCtaFeaturedLinks
              overline={item.overline}
              heading={item.heading}
              text={item.text}
              ctaLink={item.ctaLink}
              featuredLinks={item.featuredLinks}
            />
          )}
          {item.model.apiKey === 'external_video_modular_block' && (
            <ExternalVideo
              heading={item.heading}
              video={item.externalVideo}
              isNewsSingle={isNewsSingle}
            />
          )}
          {item.model.apiKey === 'featured_logos_modular_block' && (
            <FeaturedLogos heading={item.heading} logos={item.logos} />
          )}
          {item.model.apiKey === 'featured_projects_modular_block' && (
            <FeaturedProjects heading={item.heading} projects={item.projects} />
          )}
          {item.model.apiKey === 'full_width_image_modular_block' && (
            <FullWidthImage image={item.image} />
          )}
          {item.model.apiKey === 'highlighted_logos_modular_block' && (
            <HighlightedLogos heading={item.heading} logos={item.logos} />
          )}
          {item.model.apiKey === 'image_content_cta_modular_block' && (
            <ImageContentCta
              heading={item.heading}
              text={item.text}
              image={item.image}
              link={item.link}
            />
          )}
          {item.model.apiKey === 'image_content_modular_block_v1' && (
            <ImageContent
              overline={item.overline}
              image={item.image}
              heading={item.heading}
              text={item.text}
              link={item.link}
              flip={item.flip}
              version={1}
            />
          )}
          {item.model.apiKey === 'image_content_modular_block_v2' && (
            <ImageContent
              overline={item.overline}
              image={item.image}
              heading={item.heading}
              text={item.text}
              link={item.link}
              flip={item.flip}
              version={2}
            />
          )}
          {item.model.apiKey === 'image_cta_cards_modular_block' && (
            <ImageCtaCards
              heading={item.heading}
              items={item.imageCtaCards}
              link={item.link}
            />
          )}
          {item.model.apiKey === 'image_slider_modular_block' && (
            <ImageSlider images={item.images} />
          )}
          {item.model.apiKey === 'internal_video_modular_block' && (
            <InternalVideo
              heading={item.heading}
              video={item.internalVideo}
              isNewsSingle={isNewsSingle}
            />
          )}
          {item.model.apiKey === 'latest_news_modular_block' && (
            <LatestNews heading={item.heading} />
          )}
          {item.model.apiKey === 'logos_strip_modular_block' && (
            <LogosStrip heading={item.heading} logos={item.logos} />
          )}
          {item.model.apiKey === 'statement_text_modular_block' && (
            <StatementText text={item.text} />
          )}
          {item.model.apiKey === 'tabs_modular_block' && (
            <Tabs heading={item.instance.heading} items={item.instance.tabs} />
          )}
          {item.model.apiKey === 'team_profiles_modular_block' && (
            <TeamProfiles heading={item.heading} items={item.team} />
          )}
          {item.model.apiKey === 'testimonial_modular_block' && (
            <Testimonial
              heading={item.heading}
              quote={item.quote}
              author={item.author}
            />
          )}
          {item.model.apiKey === 'text_cta_modular_block' && (
            <TextCta heading={item.heading} text={item.text} link={item.link} />
          )}
        </React.Fragment>
      ))}
    </ModularBlocksComponent>
  );
};

export default ModularBlocks;

export const ModularBlockFragments = graphql`
  fragment AccordionModularBlockFragment on DatoCmsAccordionModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      accordionItems {
        id
        heading
        content
      }
    }
  }

  fragment ContainedImageModularBlockFragment on DatoCmsContainedImageModularBlock {
    id
    model {
      apiKey
    }
    image {
      gatsbyImageData(width: 1140)
      alt
    }
  }

  fragment ContentFeaturedLinksModularBlockFragment on DatoCmsContentFeaturedLinksModularBlock {
    id
    model {
      apiKey
    }
    heading
    subheading
    text
    featuredLinks {
      id
      text
      page {
        ...LinkFragment
      }
    }
  }

  fragment ContentBlocksModularBlockFragment on DatoCmsContentBlocksModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      contentBlocks {
        id
        heading
        text
      }
    }
  }

  fragment ContentModularBlockFragment on DatoCmsContentModularBlock {
    id
    model {
      apiKey
    }
    content
    twoColumns
    highlight
    contain
  }

  fragment ContentCtaFeaturedLinksModularBlockFragment on DatoCmsContentCtaFeaturedLinksModularBlock {
    id
    model {
      apiKey
    }
    overline
    heading
    text
    ctaLink {
      text
      page {
        ...LinkFragment
      }
    }
    featuredLinks {
      id
      text
      page {
        ...LinkFragment
      }
    }
  }

  fragment ExternalVideoModularBlockFragment on DatoCmsExternalVideoModularBlock {
    id
    model {
      apiKey
    }
    heading
    externalVideo {
      height
      width
      provider
      providerUid
      url
    }
  }

  fragment FeaturedLogosModularBlockFragment on DatoCmsFeaturedLogosModularBlock {
    id
    model {
      apiKey
    }
    heading
    logos {
      gatsbyImageData(height: 50)
      alt
    }
  }

  fragment FeaturedProjectsModularBlockFragment on DatoCmsFeaturedProjectsModularBlock {
    id
    model {
      apiKey
    }
    heading
    projects {
      heading
      slug
      categories {
        name
        slug
      }
      featuredImage {
        gatsbyImageData(width: 950, height: 660)
      }
    }
  }

  fragment FullWidthImageModularBlockFragment on DatoCmsFullWidthImageModularBlock {
    id
    model {
      apiKey
    }
    image {
      gatsbyImageData(width: 2000, layout: FULL_WIDTH)
      alt
    }
  }

  fragment HighlightedLogosModularBlockFragment on DatoCmsHighlightedLogosModularBlock {
    id
    model {
      apiKey
    }
    heading
    logos {
      gatsbyImageData(height: 50)
      alt
    }
  }

  fragment ImageContentCtaModularBlockFragment on DatoCmsImageContentCtaModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    image {
      ...ImageContentCtaImageFragment
    }
    link {
      text
      page {
        ...LinkFragment
      }
    }
  }

  fragment ImageContentModularBlockV1Fragment on DatoCmsImageContentModularBlockV1 {
    id
    model {
      apiKey
    }
    image {
      gatsbyImageData(width: 770, height: 850)
      alt
    }
    overline
    heading
    text
    link {
      text
      page {
        ...LinkFragment
      }
    }
    flip
  }

  fragment ImageContentModularBlockV2Fragment on DatoCmsImageContentModularBlockV2 {
    id
    model {
      apiKey
    }
    image {
      gatsbyImageData(width: 810, height: 720)
      alt
    }
    overline
    heading
    text
    link {
      text
      page {
        ...LinkFragment
      }
    }
    flip
  }

  fragment ImageCtaCardsModularBlockFragment on DatoCmsImageCtaCardsModularBlock {
    id
    model {
      apiKey
    }
    heading
    imageCtaCards {
      id
      heading
      image {
        gatsbyImageData(width: 1440, height: 600)
        alt
      }
      link {
        text
        page {
          ...LinkFragment
        }
      }
    }
    link {
      text
      page {
        ...LinkFragment
      }
    }
  }

  fragment ImageSliderModularBlockFragment on DatoCmsImageSliderModularBlock {
    id
    model {
      apiKey
    }
    images {
      gatsbyImageData(width: 1180, height: 660)
      alt
    }
  }

  fragment InternalVideoModularBlockFragment on DatoCmsInternalVideoModularBlock {
    id
    model {
      apiKey
    }
    heading
    internalVideo {
      format
      url
      video {
        thumbnailUrl
        mp4Url
      }
    }
  }

  fragment LatestNewsModularBlockFragment on DatoCmsLatestNewsModularBlock {
    id
    model {
      apiKey
    }
    heading
  }

  fragment LogosStripModularBlockFragment on DatoCmsLogosStripModularBlock {
    id
    model {
      apiKey
    }
    heading
    logos {
      gatsbyImageData(height: 50)
      alt
    }
  }

  fragment StatementTextModularBlockFragment on DatoCmsStatementTextModularBlock {
    id
    model {
      apiKey
    }
    text
  }

  fragment TabsModularBlockFragment on DatoCmsTabsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      tabs {
        id
        heading
        content
      }
    }
  }

  fragment TeamProfilesModularBlockFragment on DatoCmsTeamProfilesModularBlock {
    id
    model {
      apiKey
    }
    heading
    team {
      gatsbyImageData(width: 400, height: 480)
      title
      alt
    }
  }

  fragment TestimonialModularBlockFragment on DatoCmsTestimonialModularBlock {
    id
    model {
      apiKey
    }
    heading
    author
    quote
  }

  fragment TextCtaModularBlockFragment on DatoCmsTextCtaModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    link {
      text
      page {
        ...LinkFragment
      }
    }
  }
`;
