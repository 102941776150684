import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import NewsListing from './NewsListing';

const LatestNews = ({ heading }) => {
  const {
    allDatoCmsNews: { nodes },
  } = useStaticQuery(graphql`
    query NewsListingQuery {
      allDatoCmsNews(
        limit: 2
        sort: { fields: meta___firstPublishedAt, order: DESC }
      ) {
        nodes {
          ...NewsCardFragment
        }
      }
    }
  `);

  return <NewsListing heading={heading} items={nodes} />;
};

export default LatestNews;
