import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Container } from './ui';
import {
  absoluteCentering,
  brandColours,
  breakpointSizes,
  fontSize,
  fluidFontSize,
  minBreakpointQuery,
  sectionPaddings,
} from '../styles';

const StyledHighlightedLogos = styled.section`
  position: relative;
  ${sectionPaddings()};
  text-align: center;
`;

const StyledContainer = styled(Container)`
  position: relative;
`;

const StyledHeading = styled.h2`
  margin-bottom: 40px;
  color: ${brandColours.secondary};
  ${fontSize(45)};
  line-height: 1;
  opacity: 0.7;

  ${minBreakpointQuery.tiny`
    ${fluidFontSize(
      '50px',
      '300px',
      breakpointSizes.tiny,
      breakpointSizes.xxxxlarge
    )};
  `}

  ${minBreakpointQuery.xlarge`
    margin-bottom: 0;
    line-height: 6rem;
  `}
`;

const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;

  ${minBreakpointQuery.tiny`
    margin-top: 10px;
  `}

  ${minBreakpointQuery.smedium`
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin: 50px auto 0;
    padding: 0 30px;
    width: 100%;
    max-width: 900px;
  `}

  ${minBreakpointQuery.large`
    ${absoluteCentering()};
  `};

  ${minBreakpointQuery.xxlarge`
    max-width: none;
  `}
`;

const StyledListItem = styled.li`
  align-self: center;
  margin-bottom: 30px;
  width: 100%;

  ${minBreakpointQuery.tiny`
    width: 50%;
  `}

  ${minBreakpointQuery.smedium`
    margin: 0;
    width: auto;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  img {
    margin: auto;
    max-height: 50px;
    max-width: 200px;
    filter: grayscale(1);
  }
`;

const HighlightedLogos = ({ heading, logos }) => (
  <StyledHighlightedLogos>
    <StyledContainer>
      <StyledHeading>{heading}</StyledHeading>

      <StyledList>
        {logos.map((image, index) => (
          <StyledListItem key={index}>
            <StyledImage
              image={image.gatsbyImageData}
              objectFit="contain"
              alt={image.alt}
            />
          </StyledListItem>
        ))}
      </StyledList>
    </StyledContainer>
  </StyledHighlightedLogos>
);

export default HighlightedLogos;
