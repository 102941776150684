import React from 'react';
import styled from 'styled-components';
import { Container } from './ui';
import { ContentCta } from './ContentCta';
import { FeaturedLinks } from './FeaturedLinks';
import { minBreakpointQuery, sectionMargins } from '../styles';

const StyledContentCtaFeaturedLinks = styled.section`
  ${sectionMargins()};
`;

const StyledContainer = styled(Container)`
  ${minBreakpointQuery.medium`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `}
`;

const ContentCtaFeaturedLinks = ({
  overline,
  heading,
  text,
  ctaLink,
  featuredLinks,
}) => (
  <StyledContentCtaFeaturedLinks>
    <StyledContainer>
      <ContentCta
        overline={overline}
        heading={heading}
        text={text}
        ctaLink={ctaLink}
      />
      <FeaturedLinks featuredLinks={featuredLinks} />
    </StyledContainer>
  </StyledContentCtaFeaturedLinks>
);

export default ContentCtaFeaturedLinks;
