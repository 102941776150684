import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  visuallyHidden,
} from '../styles';
import { Container, Heading, HtmlContent } from './ui';

const StyledAccordion = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 30px;

  ${minBreakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 50px;
  `}
`;

const StyledItem = styled.article`
  margin: 20px 0;
  padding: 22px 20px 22px 30px;
  color: ${({ display }) =>
    display ? standardColours.white : brandColours.primary};
  background-color: ${({ display }) =>
    display ? brandColours.primary : brandColours.secondary};

  ${minBreakpointQuery.small`
    padding: 28px 25px 28px 40px;
  `}

  ${minBreakpointQuery.large`
    padding: 34px 30px 34px 50px;
  `}
`;

const StyledSubHeading = styled.h3`
  position: relative;
  padding-right: 30px;
  ${fontSize(18)};
  line-height: 1.5;
  cursor: pointer;

  ${minBreakpointQuery.small`
    padding-right: 35px;
    ${fontSize(20)};
  `}

  ${minBreakpointQuery.large`
    padding-right: 40px;
    ${fontSize(22)};
  `}

  &:after {
    content: ${({ display }) => (display ? "'-'" : "'+'")};
    position: absolute;
    top: 50%;
    right: 0;
    color: ${({ display }) =>
      display ? standardColours.white : brandColours.primary};
    font-weight: ${fontWeights.regular};
    ${fontSize(35)};
    transform: translateY(-50%);

    ${minBreakpointQuery.small`
      ${fontSize(40)};
    `}

    ${minBreakpointQuery.large`
      ${fontSize(45)};
    `}
  }
`;

const StyledContent = styled(HtmlContent)`
  margin-top: 20px;

  ${({ display }) => {
    if (!display) {
      return css`
        ${visuallyHidden()};
      `;
    }
  }}

  ${minBreakpointQuery.small`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const Accordion = ({ heading, items }) => {
  const [activeItem, setActiveItem] = useState(0);

  return (
    <StyledAccordion>
      <Container>
        <StyledHeading>{heading}</StyledHeading>
        {items.map(({ id, heading, content }, i) => {
          const display = activeItem === i;

          return (
            <StyledItem key={id} display={display}>
              <StyledSubHeading
                display={display}
                onClick={() => setActiveItem(display ? undefined : i)}
              >
                {heading}
              </StyledSubHeading>
              <StyledContent html={content} display={display} />
            </StyledItem>
          );
        })}
      </Container>
    </StyledAccordion>
  );
};

export default Accordion;
