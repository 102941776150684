import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { sectionMargins } from '../styles';
import { Container } from './ui';

const StyledContainedImage = styled.section`
  ${({ isNewsSingle }) =>
    isNewsSingle ? sectionMargins('30px', '50px') : sectionMargins()};
`;

const ContainedImage = ({ image: { gatsbyImageData, alt }, isNewsSingle }) => (
  <StyledContainedImage isNewsSingle={isNewsSingle}>
    <Container narrow={isNewsSingle}>
      <GatsbyImage image={gatsbyImageData} alt={alt} />
    </Container>
  </StyledContainedImage>
);

export default ContainedImage;
