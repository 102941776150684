import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Container } from './ui';
import { headingStyles, minBreakpointQuery, sectionMargins } from '../styles';

const StyledFeaturedLogos = styled.section`
  ${sectionMargins()};
  overflow-x: hidden;
`;

const StyledHeading = styled.h2`
  ${headingStyles()};
  margin-bottom: 0;
  text-align: center;
`;

const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;

  ${minBreakpointQuery.smedium`
    align-items: center;
    padding: 30px;
    width: 100%;
  `}

  ${minBreakpointQuery.medium`
    padding: 30px 0;
  `}
`;

const StyledListItem = styled.li`
  align-self: center;
  margin: 40px 0;
  width: 50%;
  text-align: center;

  ${minBreakpointQuery.tsmall`
    width: 33.33%;
  `}

  ${minBreakpointQuery.smedium`
    width: 25%;
  `}

${minBreakpointQuery.mlarge`
    width: 20%;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  img {
    margin: auto;
    max-height: 50px;
    max-width: 200px;
    filter: grayscale(1);
  }
`;

const FeaturedLogos = ({ heading, logos }) => (
  <StyledFeaturedLogos>
    <Container>
      {heading && <StyledHeading>{heading}</StyledHeading>}

      <StyledList>
        {logos.map((image, index) => (
          <StyledListItem key={index}>
            <StyledImage image={image.gatsbyImageData} alt={image.alt} />
          </StyledListItem>
        ))}
      </StyledList>
    </Container>
  </StyledFeaturedLogos>
);

export default FeaturedLogos;
