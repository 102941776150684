import React from 'react';
import styled from 'styled-components';
import {
  brandColours,
  breakpointSizes,
  fluidFontSize,
  sectionMargins,
} from '../styles';
import { Container } from './ui/Container';

const StyledStatementText = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled.h2`
  padding-bottom: 30px;
  color: ${brandColours.primary};
  ${fluidFontSize(
    '25px',
    '40px',
    breakpointSizes.tiny,
    breakpointSizes.xlarge
  )};
  line-height: 1.25;
  border-bottom: 2px solid ${brandColours.primary};
`;

const StatementText = ({ text }) => (
  <StyledStatementText>
    <Container narrow>
      <StyledHeading>{text}</StyledHeading>
    </Container>
  </StyledStatementText>
);

export default StatementText;
