import React, { useState } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import {
  sectionMargins,
  minBreakpointQuery,
  fontSize,
  fontWeights,
  maxBreakpointQuery,
} from '../styles';
import {
  Heading,
  Link,
  ArrowButton,
  SliderArrows,
  SliderDots,
  Container,
} from './ui';
import CategoriesList from './CategoriesList';

const StyledFeaturedProjects = styled.section`
  ${sectionMargins()};
`;

const StyledOverline = styled.p`
  margin-bottom: 8px;
  font-weight: ${fontWeights.medium};
  ${fontSize(12)};
  text-transform: uppercase;
  letter-spacing: 2.5px;

  ${minBreakpointQuery.small`
        margin-bottom: 10px;
        ${fontSize(14)};
    `}

  ${minBreakpointQuery.large`
        margin-bottom: 12px;
        ${fontSize(16)};
    `}
`;

const StyledSlider = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  overflow: visible;
`;

const StyledSlide = styled.li`
  display: grid;
  grid-template-columns: 1fr;
  grid-row: 1;
  grid-column: 1;
  gap: 30px;
  min-width: 100%;
  max-width: 100%;
  overflow: visible;
  pointer-events: ${({ active }) => (active ? 'all' : 'none')};

  ${minBreakpointQuery.smedium`
    grid-template-columns: 1fr 1fr;
    gap: 180px;
  `};
`;

const StyledImageWrapper = styled.div`
  position: relative;
  margin-right: -30px;

  ${maxBreakpointQuery.smedium`
    margin-left: -30px;
  `};
`;

const StyledImage = styled(GatsbyImage)`
  height: 100%;
`;

const StyledImageContent = styled.div`
  width: 100%;
  max-width: 500px;
  margin-left: auto;
`;

const FeaturedProjects = ({ heading, projects }) => {
  const [slideOpacities, setSlideOpacities] = useState([]);
  const [slider, sliderInstance] = useKeenSlider({
    loop: true,
    slides: projects.length,
    move(slide) {
      const opacities = slide.details().positions.map(s => s.portion);
      setSlideOpacities(opacities);
    },
  });

  return (
    <StyledFeaturedProjects>
      <Container wide={true}>
        <StyledSlider ref={slider} className="keen-slider">
          {projects.map((project, id) => (
            <StyledSlide
              key={id}
              className="keen-slider__slide"
              active={sliderInstance?.details().relativeSlide === id}
              style={{ opacity: slideOpacities[id] }}
            >
              <StyledImageContent>
                <StyledOverline>{heading}</StyledOverline>
                <Heading>
                  <Link to={`projects/${project.slug}`}>{project.heading}</Link>
                </Heading>

                {project.categories.length > 0 && (
                  <CategoriesList items={project.categories} />
                )}

                <ArrowButton to={`projects/${project.slug}`}>
                  View Project
                </ArrowButton>

                <SliderDots sliderInstance={sliderInstance} slides={projects} />
              </StyledImageContent>
              <StyledImageWrapper>
                <StyledImage
                  image={project.featuredImage.gatsbyImageData}
                  alt={project.featuredImage.alt ?? ''}
                />
                <SliderArrows sliderInstance={sliderInstance} />
              </StyledImageWrapper>
            </StyledSlide>
          ))}
        </StyledSlider>
      </Container>
    </StyledFeaturedProjects>
  );
};

export default FeaturedProjects;
