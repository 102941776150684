import React from 'react';
import styled from 'styled-components';
import { Container, SliderArrows } from './ui';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  maxBreakpointQuery
} from '../styles';

const StyledImageSlider = styled.section`
  ${maxBreakpointQuery.tiny`
    margin-right: -30px;
    margin-left: -30px;
  `}
`;

const StyledImageList = styled.ul``;

const StyledImageItem = styled.li``;

const StyledSlider = styled.div`
  position: relative;
`;

const ImageSlider = ({ images }) => {
  const [slider, sliderInstance] = useKeenSlider({
    initial: 0,
    loop: true,
  });

  return (
    <StyledImageSlider>
      <Container>
        <StyledSlider>
          <StyledImageList ref={slider} className="keen-slider">
            {images.map(({ gatsbyImageData, alt }, id) => (
              <StyledImageItem key={id} className="keen-slider__slide">
                <GatsbyImage image={gatsbyImageData} alt={alt} />
              </StyledImageItem>
            ))}
          </StyledImageList>
          
          <SliderArrows sliderInstance={sliderInstance} alt={true} />
        </StyledSlider>
      </Container>
    </StyledImageSlider>
  );
};

export default ImageSlider;
