import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  brandColours,
  fontWeights,
} from '../styles';
import { Container, Heading, HtmlContent, ArrowButton } from './ui';

const StyledImageContent = styled.section`
  ${sectionMargins()};
`;

const StyledInner = styled.div`
  display: grid;
  gap: 30px;

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 40px;
  `}

  ${minBreakpointQuery.medium`
    gap: 60px;
  `}

  ${minBreakpointQuery.mlarge`
    gap: 90px;
  `}

  ${minBreakpointQuery.large`
    gap: 120px;
  `}

  ${minBreakpointQuery.xlarge`
    gap: 150px;
  `}

  ${minBreakpointQuery.xxlarge`
    gap: 180px;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  ${maxBreakpointQuery.smedium`
    aspect-ratio: 2/1;
    margin-right: -30px;
    margin-left: -30px;

    @supports not (aspect-ratio: 3/2) {
      max-height: 430px;
    }
  `}

  ${maxBreakpointQuery.small`
    aspect-ratio: 3/2;
  `}

  ${maxBreakpointQuery.tsmall`
    aspect-ratio: 4/3;
  `}

  ${minBreakpointQuery.smedium`
    height: 100%;
  `}

  ${({ flip }) => {
    if (flip) {
      return css`
        ${minBreakpointQuery.smedium`
          grid-row: 1;
          grid-column: 2;
        `}
      `;
    }
  }}

  ${({ version }) => {
    if (version === 2) {
      return css`
        ${minBreakpointQuery.smedium`
          ${({ flip }) =>
            flip ? 'margin-right: -30px;' : 'margin-left: -30px;'}
        `}
      `;
    }
  }}
`;

const StyledContent = styled.div`
  ${minBreakpointQuery.smedium`
    margin-top: 20px;
    margin-bottom: 20px;
  `}

  ${({ version }) => {
    if (version === 2) {
      return css`
        ${minBreakpointQuery.smedium`
          ${({ flip }) => (flip ? 'margin-left: auto;' : 'margin-right: auto;')}
          max-width: 580px;
        `}
      `;
    }
  }}
`;

const StyledOverline = styled.p`
  margin-bottom: 12px;
  color: ${brandColours.primary};
  font-weight: ${fontWeights.medium};
  text-transform: uppercase;
  letter-spacing: 2.5px;

  ${minBreakpointQuery.small`
    margin-bottom: 16px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 20px;
  `}
`;

const StyledText = styled(HtmlContent)`
  margin-top: 15px;

  ${minBreakpointQuery.tiny`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.small`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 35px;
  `}
`;

const StyledLink = styled(ArrowButton)`
  margin-top: 20px;

  ${minBreakpointQuery.tiny`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.small`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 35px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
  `}
`;

const ImageContent = ({
  image,
  overline,
  heading,
  text,
  link,
  flip,
  version,
}) => (
  <StyledImageContent>
    <Container wide={version === 2}>
      <StyledInner>
        <StyledImage
          image={image.gatsbyImageData}
          alt={image.alt}
          flip={flip}
          version={version}
        />
        <StyledContent flip={flip} version={version}>
          {overline && <StyledOverline>{overline}</StyledOverline>}
          <Heading>{heading}</Heading>
          {text && <StyledText html={text} />}
          {link && <StyledLink to={link.page}>{link.text}</StyledLink>}
        </StyledContent>
      </StyledInner>
    </Container>
  </StyledImageContent>
);

export default ImageContent;
